const Utils = require('../utils');
const DivSlot = require('../divSlot');
const AdserverBase = require('../adserverBase');

class AdnuntiusSlot extends DivSlot {}

const withAdnuntius = ((fn) => {
	window.adn = window.adn || {};
	const { adn } = window;
	adn.calls = adn.calls || [];
	adn.calls.push(() => fn(adn));
});

class AdnuntiusAdserver extends AdserverBase {
	adUnitPathsFromUnitInternal({ adnIds }) {
		return adnIds;
	}

	getType() {
		return 'adnuntius';
	}

	updateAdUnitPath(dstAdUnit, adUnitPath) {
		dstAdUnit.adnIds = [adUnitPath];
		return true;
	}

	/**
	 * As Adnuntius' .js doesn't have the concept of slots/tags that are created beforehand we'll create
	 * our own "slots" whenever new placements pops up in the auction.adnRequest.adUnits array. */
	init({ adnRequest }, doneCb) {
		adnRequest?.adUnits?.forEach(({ auId, targetId }) => {
			if (auId && targetId) {
				AdnuntiusSlot.getOrCreateSlot(targetId, auId);
			}
		});
		doneCb();
	}

	getSlots() {
		return AdnuntiusSlot.list();
	}

	createSlot({ divId, path }) {
		return AdnuntiusSlot.getOrCreateSlot(divId, path);
	}

	sendAdserverRequest(...args) {
		withAdnuntius((adn) => {
			this.adn = adn;
			this.sendAdserverRequestInternal(...args);
		});
	}

	/** For overriding our calls using e.g: relevantDigital.loadPrebid({ adntagCalls: { request: (req) => ... } }) */
	adnCall(fnName, ...args) {
		const fn = this.auction.adntagCalls?.[fnName];
		return fn ? fn.call({ auction: this.auction }, ...args) : this.adn[fnName](...args);
	}

	sendAdserverRequestInternal({ requestAuction, unknownSlotsToLoad }) {
		const { pbjs, adnRequest } = requestAuction;
		const usedUnitDatas = requestAuction.unitDatasByAds(this);
		// Manually provided ad units by div-id (if any)
		const existingByTagId = Utils.keyBy(adnRequest?.adUnits, 'targetId');

		// Slots not found in config
		const unknown = unknownSlotsToLoad.map((slot) => existingByTagId[slot.getSlotElementId()]).filter((s) => s);

		const adUnits = usedUnitDatas.map(({ slot, adUnit }) => {
			const sz = adUnit.getPrimaryPrebidSize();
			return {
				auId: slot.getAdUnitPath(),
				targetId: slot.getSlotElementId(),
				auW: (sz?.[0] || 0).toString(),
				auH: (sz?.[1] || 0).toString(),
				collapsible: !!slot.collapse,
				...existingByTagId[slot.getSlotElementId()],
			};
		}).concat(unknown);

		// Setup headerBids-array in the same way as in adn.js
		const asArr = (fn) => [].concat(...Object.values(pbjs?.[fn]?.() || {}).map((o) => o.bids));
		const headerBids = asArr('getBidResponses').map(Utils.clone);
		headerBids.push(...asArr('getNoBids').map((noBid) => {
			const copy = Utils.clone(noBid);
			copy.cpm = 0;
			copy.statusMessage = 'Bid returned empty or error response';
			copy.bidderCode = copy.bidderCode || copy.bidder || '';
			return copy;
		}));
		headerBids.forEach((bid) => {
			if (!bid.dealId) {
				delete bid.dealId;
			}
		});
		const params = { ...adnRequest, adUnits, headerBids };
		const cbSet = Utils.callbackSet(); // Use callbackSet() to also call (possibly) supplied onResponse callback
		cbSet.add({
			onResponse: ({ targetId }) => window.relevantDigital.registerRenderedDivId(targetId),
		});
		cbSet.apply(params);
		this.adnCall('request', params);
	}
}

module.exports = AdnuntiusAdserver;
