const DivSlot = require('../divSlot');
const AdserverBase = require('../adserverBase');
const HbmUtils = require('../hbmUtils');
const Utils = require('../utils');
class InMemorySlot extends DivSlot {}

class InMemoryAdserver extends AdserverBase {
	adUnitPathsFromUnitInternal({ inMemoryIds }) {
		return inMemoryIds;
	}

	getType() {
		return 'in_memory';
	}

	getSlots() {
		return InMemorySlot.list();
	}

	createSlot({ divId, path }) {
		return InMemorySlot.getOrCreateSlot(divId, path);
	}

	getAmazonIntegrationInfo() {
		return {
			adServerName: null,
			useSetDisplayBids: false,
			requiresRenderHook: true,
		};
	}

	sendAdserverRequest({ requestAuction }) {
		const { inMemoryRender } = requestAuction;
		const usedUnitDatas = requestAuction.unitDatasByAds(this);
		const renderInMemory = ({ slot, code, bid, adDiv, amazonBid }) => {
			adDiv.innerHTML = '';
			if (bid || amazonBid) {
				HbmUtils.renderBid({ auction: requestAuction, bid, code, adDiv, amazonBid });
			}
			window.relevantDigital.registerRenderedDivId(slot.getSlotElementId());
		};
		const renderFn = inMemoryRender || renderInMemory;
		usedUnitDatas.forEach((unitData) => {
			const { code, slot, adUnit, amazonBid } = unitData;
			const bid = unitData.getHighestBid();
			const bidParam = (this.preferAmazonBids && amazonBid) || !bid ? { amazonBid } : { bid };
			const adDiv = document.getElementById(slot.getSlotElementId());
			const params = {
				adUnit, code, slot, adDiv, ...bidParam,
			};
			renderFn({ ...params, fn: () => renderInMemory(params) });
		});
	}
}

module.exports = InMemoryAdserver;
