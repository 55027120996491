const AdserverBase = require('../adserverBase');
const DivSlot = require('../divSlot');

class DemoAdserver extends AdserverBase {
	getSlots() {
		const slots = [];
		this.auction.adUnits.forEach(({ placementAdserverId }) => {
			if (document.getElementById(placementAdserverId)) {
				slots.push(DivSlot.getOrCreateSlot(placementAdserverId, placementAdserverId));
			}
		});
		return slots;
	}

	adUnitPathsFromUnitInternal({ placementAdserverId }) {
		return [placementAdserverId];
	}

	sendAdserverRequest({ requestAuction }) {
		const usedUnitDatas = requestAuction.unitDatasByAds(this);
		usedUnitDatas.forEach((unitData) => {
			const { adUnit } = unitData;
			const { placementAdserverId } = adUnit;
			window.demoAdserver.render(adUnit, unitData.getHighestBid(), placementAdserverId);
			window.relevantDigital.registerRenderedDivId(placementAdserverId);
		});
	}
}

module.exports = DemoAdserver;
